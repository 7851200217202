import { create } from '@storybook/theming/create';

export default create({
  base: 'dark',

  // Typography
  fontBase: '"DM Sans", sans-serif',

  brandTitle: 'Flux UI',
  brandUrl: 'https://customer.krakenflex.com/dashboard',
  brandImage:
    'https://customer.krakenflex.com/assets/shared/krakenflex/dark/monogram.svg',
  brandTarget: '_blank',

  // Colours
  colorPrimary: '#C3C5C9',
  colorSecondary: '#414652',

  // UI
  appBg: '#111827',
  appContentBg: '#111827',
  appBorderColor: '#292F3D',
  appBorderRadius: 0,

  // Text colors
  textColor: '#F9FAFB',
  textInverseColor: '#111827',
  textMutedColor: '#C3C5C9',

  // Toolbar default and active colors
  barTextColor: '#C3C5C9',
  barSelectedColor: '#AE73FD',
  barBg: '##111827',

  // Form colors
  inputBg: '#111827',
  inputBorder: '#292F3D',
  inputTextColor: '#F9FAFB',
  inputBorderRadius: 4,
  buttonBg: '#585D68',
  buttonBorder: '#585D68',
  booleanBg: '#111827',
  booleanSelectedBg: '#414652',
});
